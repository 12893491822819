<template>
  <div class="consumables">
    <BaseDialog
      :title="ruleForm.id ? '修改透析分组' : '新增透析分组'"
      :isshow="visible"
      @handleShow="
        (val) => {
          visible = val
        }
      "
      width="900px"
      top="5%"
    >
      <div class="consumables-search">
        <el-form
          :model="ruleForm"
          ref="ruleFormRef"
          :rules="Rules.ADDDOPERATION"
          label-width="110px"
          label-position="right"
          :inline="true"
          class="demo-ruleForm col-333"
        >
          <el-form-item label="设备类型：" prop="dict_machine_type">
            <el-select
              v-model="ruleForm.dict_machine_type"
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList[170000000]"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备编号：" prop="machine_no">
            <el-input
              v-model="ruleForm.machine_no"
              placeholder="保存之后不可以修改"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备名称：" prop="machine_name">
            <el-input
              v-model="ruleForm.machine_name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="机器状态：">
            <el-select v-model="ruleForm.run_status" placeholder="请选择">
              <el-option label="正常" :value="1"></el-option>
              <el-option label="维修" :value="2"></el-option>
              <el-option label="报废" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备型号：">
            <el-select
              v-model="ruleForm.dict_machine_model"
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList[172000000]"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产地：">
            <el-select
              v-model="ruleForm.dict_product_addr"
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList[173000000]"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="厂家：">
            <el-select v-model="ruleForm.dict_factory" placeholder="请选择">
              <el-option
                v-for="item in dictList[233000000]"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="出厂编号：">
            <el-input v-model="ruleForm.out_no" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="购买价格：">
            <el-input v-model="ruleForm.price" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="购买日期：">
            <el-date-picker
              v-model="ruleForm.buy_time"
              type="date"
              value-format="YYYY-MM-DD"
              placeholder="请选择"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="安装日期：">
            <el-date-picker
              v-model="ruleForm.install_time"
              type="date"
              value-format="YYYY-MM-DD"
              placeholder="请选择"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="启用日期：">
            <el-date-picker
              v-model="ruleForm.start_time"
              type="date"
              value-format="YYYY-MM-DD"
              placeholder="请选择"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="保修日期：">
            <el-date-picker
              v-model="ruleForm.period_time"
              type="date"
              value-format="YYYY-MM-DD"
              placeholder="请选择"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="存放地点：">
            <el-input
              v-model="ruleForm.deposit"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="感染标记：" prop="infect_flag">
            <el-select
              v-model="ruleForm.infect_flag"
              multiple
              @change="infectFlagChang"
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList[108000000]"
                :key="item.code"
                :disabled="item.disabled"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="消毒时长：">
            <el-input
              v-model="ruleForm.disinfect_long"
              placeholder="单位/分钟"
            ></el-input>
          </el-form-item>
          <el-form-item label="联机盒IP：">
            <el-input
              v-model="ruleForm.online_ip"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="联机盒MAC：">
            <el-input
              v-model="ruleForm.online_mac"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="治疗特征：" prop="dict_treat_model">
            <el-select
              v-model="ruleForm.dict_treat_model"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList[100000000]"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="院区：">
            <el-select v-model="ruleForm.hospital_area_id" placeholder="请选择">
              <el-option
                v-for="item in hospitalList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="savePatient"
            :loading="saveLoad"
            class="color-determine"
            type="primary"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineExpose,
  defineEmits,
  onMounted,
  nextTick,
} from 'vue'
import service from '@/utils/request'
import BaseDialog from '@/components/Dialog/index.vue'
import { ElMessage } from 'element-plus'
import Rule from '@/utils/rule'
import { gitDictList, TimeYear } from '@/utils/tool'

const userInfo = localStorage.getItem('ms_userData')
const ruleForm = ref({})
ruleForm.value.hospital_area_id = userInfo.hospital
ruleForm.value.dict_machine_type = '170110000'
ruleForm.value.run_status = 1
const visible = ref(false)
const hospitalList = ref([])
const ruleFormRef = ref(null)
const dictList = ref({})
const Rules = reactive(Rule)
const isNegative = ref(false)
const isPositive = ref(false)

onMounted(() => {
  getDict()
  getHospitalList()
})
const initData = (item) => {
  visible.value = true
  if (item) {
    ruleForm.value = item
    ruleForm.value.buy_time = TimeYear(ruleForm.value.buy_time)
    ruleForm.value.install_time = TimeYear(ruleForm.value.install_time)
    ruleForm.value.start_time = TimeYear(ruleForm.value.start_time)
    ruleForm.value.period_time = TimeYear(ruleForm.value.period_time)
    infectFlagChang(ruleForm.value.infect_flag)
  } else {
    ruleForm.value = {
      hospital_area_id: userInfo.hospital,
      dict_machine_type: '170110000',
      run_status: 1,
    }
    isNegative.value = false
    isPositive.value = false
    if (hospitalList.value.length > 0 && !ruleForm.value.hospital_area_id) {
      ruleForm.value.hospital_area_id = hospitalList.value[0].id
    }
  }
  nextTick(() => {
    ruleFormRef.value.clearValidate()
  })
}
const infectFlagChang = (val) => {
  if (val.length <= 0) {
    isNegative.value = false
    isPositive.value = false
  } else if (val.indexOf('108180000') > -1) {
    isNegative.value = true
    isPositive.value = false
  } else {
    isNegative.value = false
    isPositive.value = true
  }
  dictList.value[108000000].map((item) => {
    if (item.code == '108180000') {
      item.disabled = isPositive.value
    } else {
      item.disabled = isNegative.value
    }
  })
}
const getDict = async () => {
  dictList.value = await gitDictList([
    '100000000',
    '170000000',
    '108000000',
    '233000000',
    '173000000',
    '172000000',
  ])
  dictList.value[108000000].map((item) => {
    item.disabled = false
  })
}
const getHospitalList = async () => {
  let res = await service.post('/api/hospital/list', {
    parent_id: 0,
  })
  if (res.code === 0) {
    hospitalList.value = res.data
    if (!ruleForm.value.hospital_area_id) {
      ruleForm.value.hospital_area_id = res.data[0].id
    }
  }
}

// 保存
const saveLoad = ref(false)
const em = defineEmits(['refreshDataList'])
const savePatient = async () => {
  ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      let url = ''
      if (ruleForm.value.id) {
        url = '/api/machine/edit'
      } else {
        url = '/api/machine/create'
      }
      saveLoad.value = true
      let res = await service.post(url, ruleForm.value)
      saveLoad.value = false
      if (res.code === 0) {
        ElMessage.success(res.msg)
        visible.value = false
        em('refreshDataList')
      }
    }
  })
}

defineExpose({
  initData,
})
</script>

<style scoped lang="scss">
.consumables-search {
  margin-top: 16px;
  .el-form-item {
    width: 47%;
    margin-right: 6%;
    &:nth-child(2n) {
      margin-right: 0;
    }
    &.addr {
      width: 100%;
      margin-right: 0;
    }
  }
}
</style>
